import React, { useState } from "react";
import { Header, Portal, Segment } from "semantic-ui-react";

import { Indicator, Stack, Text } from "Atoms";
import { gaProductionEvent } from "Utils";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY } from "Types";

export const BackendConnectionIndicator = props => {
    const [openStatusPortal, setOpenStatusPortal] = useState(false);
    const { isBackendConnected, isControlUnitConnected, isPrinterConnected, disableClick, cloudCardTerminalConnected } =
        props;
    const backendOk = isBackendConnected !== undefined ? isBackendConnected : true;
    const controlUnitOk = isControlUnitConnected !== undefined ? isControlUnitConnected : true;
    const printerOk = isPrinterConnected !== undefined ? isPrinterConnected : true;
    const cloudCardTerminalOk = cloudCardTerminalConnected !== undefined ? cloudCardTerminalConnected : true;
    const mergedStatus = backendOk && controlUnitOk && printerOk && cloudCardTerminalOk;
    let numberOfErrors = 0;
    if (!mergedStatus) {
        numberOfErrors = [backendOk, controlUnitOk, printerOk, cloudCardTerminalOk].reduce(
            (n, val) => n + (val === false),
            0
        );
    }
    return (
        <div
            style={{ cursor: "pointer" }}
            onClick={() => {
                if (!disableClick) {
                    gaProductionEvent({
                        category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
                        action: GOOGLE_ANALYTICS_EVENT_ACTION.POPUP,
                        label: "Open connection indicator"
                    })
                    setOpenStatusPortal(!openStatusPortal);
                }
            }}
        >
            <Portal onClose={() => setOpenStatusPortal(false)} open={openStatusPortal}>
                <Segment
                    style={{
                        bottom: "70px",
                        position: "fixed",
                        right: "0px",
                        zIndex: 1000,
                        backgroundColor: "rgb(51, 55, 69)",
                        color: "#87CC9B"
                    }}
                >
                    <Header style={{ color: "#87CC9B" }}>Status</Header>
                    {typeof isBackendConnected !== "undefined" ? (
                        <Stack isInline stretch={2} align="center">
                            <Indicator
                                status={isBackendConnected ? "positive" : "inactive"}
                                className={`${isBackendConnected ? "" : "flashing"}`}
                            />
                            {isBackendConnected ? (
                                <span> Server</span>
                            ) : (
                                <span style={{ color: "#f76056" }}> Server Ej Ansluten</span>
                            )}
                            <br />
                        </Stack>
                    ) : null}
                    {typeof isControlUnitConnected !== "undefined" ? (
                        <Stack isInline stretch={2} align="center">
                            <Indicator
                                status={isControlUnitConnected ? "positive" : "inactive"}
                                className={`${isControlUnitConnected ? "" : "flashing"}`}
                            />
                            {isBackendConnected ? (
                                <span> Kontrollenhet</span>
                            ) : (
                                <span style={{ color: "#f76056" }}> Kontrollenhet Ej Ansluten</span>
                            )}
                            <br />
                        </Stack>
                    ) : null}
                    {typeof isPrinterConnected !== "undefined" ? (
                        <Stack isInline stretch={2} align="center">
                            <Indicator
                                status={isPrinterConnected ? "positive" : "inactive"}
                                className={`${isPrinterConnected ? "" : "flashing"}`}
                            />
                            {isPrinterConnected ? (
                                <span> Skrivare</span>
                            ) : (
                                <span style={{ color: "#f76056" }}> Skrivare Ej Ansluten</span>
                            )}
                            <br />
                        </Stack>
                    ) : null}
                    {typeof cloudCardTerminalConnected !== "undefined" ? (
                        <Stack isInline stretch={2} align="center">
                            <Indicator
                                status={cloudCardTerminalConnected ? "positive" : "inactive"}
                                className={`${cloudCardTerminalConnected ? "" : "flashing"}`}
                            />
                            {cloudCardTerminalConnected ? (
                                <span> Kortterminal</span>
                            ) : (
                                <span style={{ color: "#f76056" }}> Kortterminal Ej Ansluten</span>
                            )}
                            <br />
                        </Stack>
                    ) : null}
                </Segment>
            </Portal>
            <Stack h="auto" stretch={1}>
                <Stack isInline stretch={2} h="auto" align="center">
                    <Indicator
                        size="lg"
                        status={mergedStatus ? "positive" : "inactive"}
                        className={`${mergedStatus ? "" : "flashing"}`}
                    />
                    {mergedStatus ? (
                        <Text color="#87CC9B">Status Ok</Text>
                    ) : (
                        <Text color="red.500">{numberOfErrors} Fel</Text>
                    )}
                </Stack>

                <span style={{ color: "#87CC9B" }}>Powered by qopla</span>
            </Stack>
        </div>
    );
};
