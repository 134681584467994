import React from "react";
import Timer from "./Timer";
import { PING_CONTROL_UNIT, PING_BACKEND } from "../../graphql/mutations/AuthenticationMutations";
import { Mutation } from "react-apollo";
import { DEFAULT_CONTROL_UNIT_HEARTBEAT } from "Constants";

export const HeartBeatTimer = props => {
    const intervalTime = props.intervalTime ? props.intervalTime : 30000;
    const onlineEnabled = props.onlineEnabled ? props.onlineEnabled : false;
    return (
        <Mutation
            mutation={PING_BACKEND}
            variables={{ shopId: props.shopId, companyId: props.companyId, onlineEnabled }}
        >
            {heartBeatPing => {
                const testFunction = () => {
                    heartBeatPing({
                        update: (_, { data: { pingBackend } }) => {
                            props.onHeartbeatChange(pingBackend);
                            return pingBackend;
                        }
                    }).catch(res => {
                        props.onHeartbeatChange({ mothershipAlive: false });
                    });
                };
                return <Timer intervalTime={intervalTime} doFunction={testFunction} />;
            }}
        </Mutation>
    );
};

export const backendHeartbeat = async (client, shopId, companyId) => {
    const { data } = await client
        .mutate({ mutation: PING_CONTROL_UNIT, variables: { shopId, companyId } })
        .catch(() => DEFAULT_CONTROL_UNIT_HEARTBEAT);
    return !!data ? data.pingControlUnit : DEFAULT_CONTROL_UNIT_HEARTBEAT;
};

export const BackendHeartbeatTimer = props => {
    return (
        <Mutation
            mutation={PING_CONTROL_UNIT}
            variables={{ shopId: props.shopId, companyId: props.companyId }}
        >
            {ping => {
                const testFunction = () => {
                    ping({
                        update: (_, { data: { pingControlUnit } }) => {
                            props.onHeartbeatChange(pingControlUnit);
                            return pingControlUnit;
                        }
                    }).catch(res => {
                        props.onHeartbeatChange(DEFAULT_CONTROL_UNIT_HEARTBEAT);
                    });
                };
                return (
                    <Timer
                        intervalTime={!!props.intervalTime ? props.intervalTime : 30000}
                        doFunction={testFunction}
                    />
                );
            }}
        </Mutation>
    );
};
