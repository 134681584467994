import moment from "moment";

import { Shop, ActiveHour } from "Types";
import { formatDateToLocal, getActiveHourByWeekday } from "Utils";

export const getCateringPickupText = (
    orderTimeInterval: string | null,
    orderPickupTime: string,
    selectedShop: Shop,
    shouldShowActiveHours: boolean
) => {
    const hasNoPickupHours = selectedShop?.settings.cateringSettings.noPickupHours;
    const pickupDate = moment(orderPickupTime).format("YYYY-MM-DD");

    //timeInterval is specific field for Matlådor, for home delivery only
    if (orderTimeInterval) {
        return `${pickupDate} ${orderTimeInterval}`;
    } else if (hasNoPickupHours) {
        const activeHours = selectedShop?.activeHours as ActiveHour[];
        const activeHour = getActiveHourByWeekday(activeHours, moment(orderPickupTime).format("dddd").toUpperCase());
        return shouldShowActiveHours
            ? `${pickupDate} (${activeHour?.startingHour} - ${activeHour?.stoppingHour})`
            : pickupDate;
    } else {
        return formatDateToLocal(orderPickupTime!.toString(), "YYYY-MM-DD HH:mm");
    }
};
