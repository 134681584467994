import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";

import { VALIDATE_USER_EMAIL } from "GraphQLQueries";

// Important: Please don't include this in any Alias...index.ts file.  If a util uses network, it should be local, not global.
// Important: Do not include `typedMothershipClient` in this util file.  It should be passed in as a parameter.
export const validateUsersEmail = async (client: ApolloClient<NormalizedCacheObject>, email: string) => {
    const validEmail = await client.query({
        query: VALIDATE_USER_EMAIL,
        variables: { email: email },
        fetchPolicy: "network-only"
    });
    return validEmail.data.validateUserEmail.email !== email;
};

//TODO: move other validating functions in here no need to pass the client to the files
//TODO: Create new issue
