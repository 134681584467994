const emptyModObj = {
    sizes: { name: null, price: null, addonPrice: null },
    flavours: { name: null, price: null, addonPrice: null }
};

export const buildBundleModifications = bundleItems => {
    return Object.values(bundleItems).reduce((acc, entry) => {
        const newBundleItems = entry.choosenBundleItems.map(bundleItem => {
            const { ...bundleItems } = bundleItem;
            return { ...bundleItems, modifications: bundleItem.modifications || emptyModObj };
        });
        return acc.concat(newBundleItems);
    }, []);
};
