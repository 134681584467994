import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";
import "../components/menu/menu.css";

import { paymentAccountTypes, PaymentMethod } from "Constants";
import { swedishDayOfWeek, normalizeString } from "Utils";
import { NewContainer } from "Atoms";

/**  @deprecated - Please import directly from "Utils" or "Utils/imageUtils" */
export { normalizeString };

const EDITED_TREE_NODE = "editedTreeNode";

export const paymentAccountTypeText = paymentAccountType => {
    switch (paymentAccountType) {
        case paymentAccountTypes.BANK_GIRO:
            return "BankGiro";
        case paymentAccountTypes.POST_GIRO:
            return "PlusGiro";
        case paymentAccountTypes.BANK_ACCOUNT:
            return "Bankkonto";
        default:
            return "";
    }
};

/**
 * //TODO: go through all places where this is and swap out for translation function for react table found in reactTableHelpers.ts
 */
export const swedishReactTableProperties = {
    previousText: "Föregående",
    nextText: "Nästa",
    noDataText: "Ingen data hittades",
    pageText: "Sida",
    ofText: "av",
    rowsText: "rader"
};

export const MustPairExpressPos = () => (
    <NewContainer>
        <h2>Du måste para en expresskassa till denna enhet först</h2>
        <Link to="/admin/expressAdmin">Gå till para expresskassa</Link>
    </NewContainer>
);

export const CouldNotFindPairedExpressPos = ({ message = "Den parade expresskassan hittas ej, var god para om" }) => {
    return (
        <NewContainer>
            <h2>{message !== "no-terminal" ? message : "Den parade expresskassan har ingen vald terminal"}</h2>
            <Link to="/admin/expressAdmin">Gå till inställningar för expresskassa</Link>
        </NewContainer>
    );
};

export const MustSelectPos = () => (
    <NewContainer>
        <h2>Du måste para en kassa till denna enhet först</h2>
        <Link to="/admin/posAdmin" data-test="link-must-select-pos">
            Gå till para kassa
        </Link>
    </NewContainer>
);

export const MustCreatePos = () => (
    <NewContainer>
        <h2>Du måste lägga till en kassa först</h2>
        <Link to="/admin/posAdmin">Gå till lägg till kassa</Link>
    </NewContainer>
);

export const MustCreateTerminal = () => (
    <NewContainer>
        <h2>Du måste lägga till en terminal först</h2>
        <Link to="/admin/terminalAdmin">Gå till lägg till terminal</Link>
    </NewContainer>
);

export const getMenuOccurrenceInShops = (shops, menuId) => {
    return shops
        ? shops.reduce((result, shop) => (shop.menuIds && shop.menuIds.includes(menuId) ? result + 1 : result), 0)
        : 0;
};

const pluck = key => object => object[key];
const add = (x, y) => x + y;

const hasRefProductPrice = ({ defaultPrice }) => defaultPrice > 0;
const hasMenuProductPrice = ({ price }) => price > 0;

// used to hide price if products have modifications with price
export const modificationsHasPrice = modifications => {
    return Object.keys(modifications).some(mod => {
        if (!!modifications[mod] && modifications[mod].length > 0) {
            return modifications[mod].some(modItem => modItem.price > 0);
        } else {
            return false;
        }
    });
};

export const modificationsHasAddonPrice = modifications => {
    return Object.keys(modifications).some(mod => {
        if (!!modifications[mod] && modifications[mod].length > 0) {
            return modifications[mod].some(modItem => modItem.addonPrice !== 0);
        } else {
            return false;
        }
    });
};

//TODO: change name later?
export const allModificationsHasPrices = modifications => {
    const lol = Object.keys(modifications).map(key => {
        if (!!modifications[key]) {
            if (modifications[key].addonPrice > 0) return false;
            return modifications[key] ? selectModPrice(modifications[key]) > 0 : false;
        } else {
            return false; //because then the modification does not exist, and comes in as an empty array from mothership.
        }
    });
    return lol.includes(true);
};

export const findLowestModificationAddonPrice = modifications => {
    if (!modifications) return;
    const { flavours, sizes } = modifications;
    const getMin = mods => mods.reduce((minimum, current) => (current < minimum ? current : minimum));

    const minF = flavours.length > 0 ? getMin(flavours.map(({ addonPrice }) => addonPrice)) : 0;
    const minS = sizes.length > 0 ? getMin(sizes.map(({ addonPrice }) => addonPrice)) : 0;

    return minF + minS;
};

export const findLowestModificationPrice = modifications => {
    if (!modifications) return;
    const { flavours, sizes } = modifications;
    const getMin = mods => mods.reduce((minimum, current) => (current < minimum ? current : minimum));

    const minF = flavours.length > 0 ? getMin(flavours.map(({ price, addonPrice }) => price + addonPrice)) : 0;
    const minS = sizes.length > 0 ? getMin(sizes.map(({ price, addonPrice }) => price + addonPrice)) : 0;

    return minF + minS;
};

const selectModPrice = pluck("price");
const selectRefProductPrice = pluck("defaultPrice");
const selectMenuProductPrice = pluck("price");

const selectBasePrice = menuProduct => {
    const { refProduct, refBundleProduct } = menuProduct;

    if (refBundleProduct) {
        if (!menuProduct.price) {
            return refBundleProduct.defaultPrice;
        } else {
            return menuProduct.price;
        }
    }

    return hasMenuProductPrice(menuProduct)
        ? selectMenuProductPrice(menuProduct)
        : hasRefProductPrice(refProduct)
        ? selectRefProductPrice(refProduct)
        : 0;
};

const selectModificationPrice = modification => {
    if (!modification || modification === null) return 0;

    const { price, addonPrice } = modification;
    const modPrice = price > addonPrice ? price : addonPrice;
    return modPrice ? modPrice : 0;
};

export const getModificationLengths = modifications => {
    return {
        sizes: modifications && modifications.sizes ? modifications.sizes.length : 0,
        flavours: modifications && modifications.flavours ? modifications.flavours.length : 0
    };
};

export const getAddonPrice = addons => (addons ? addons.reduce((a, b) => a + b.price * b.quantity, 0) : 0);

export const calculateMenuProductPrice = menuProduct => {
    const { modifications, refBundleProduct, addons } = menuProduct;

    const addonPrice = getAddonPrice(addons);
    if (refBundleProduct) return selectBasePrice(menuProduct) + addonPrice;
    if (!modifications) return selectBasePrice(menuProduct) + addonPrice;
    else {
        // if only single mod and it has price, return price
        const { sizes, flavours } = getModificationLengths(modifications);
        if (sizes + flavours === 1) {
            if (sizes > 0 && modifications.sizes[0].price > 0) {
                return modifications.sizes[0].price + addonPrice;
            } else if (flavours > 0 && modifications.flavours[0].price > 0) {
                return modifications.flavours[0].price + addonPrice;
            }
        }
    }
    return calculateModifications(modifications, menuProduct) + addonPrice;
};

export const calculateModifications = (modifications, menuProduct) => {
    const price = Object.keys(modifications)
        .map(key => {
            return selectModificationPrice(modifications[key]);
        })
        .reduce(add, allModificationsHasPrices(modifications) ? 0 : selectBasePrice(menuProduct));
    return price ? price : 0;
};

export const reCalculateModificationPrice = (modifications, menuProductPrice) => {
    return Object.keys(modifications)
        .map(key => {
            return selectModificationPrice(modifications[key]);
        })
        .reduce(add, allModificationsHasPrices(modifications) ? 0 : menuProductPrice);
};

export const productSubtitleWithPriceAndMods = (price, modifications, isCombo) => {
    return `Pris ${price} kr ${modifications ? " | Har modifikationer" : ""} ${isCombo ? " | Komboprodukt" : ""}`;
};

export const productCategorySubtitle = children => {
    return `Produkter: ${children.length}`;
};

export const isEditedTreeNode = (editedNodes, id) => {
    return editedNodes.includes(id) || typeof id === "undefined" ? EDITED_TREE_NODE : "";
};

export const isEditedProductTreeNode = (editedNodes, id) => {
    return editedNodes.find(prod => prod.id == id) || typeof id === "undefined" ? EDITED_TREE_NODE : "";
};

export const formatDateToLocal = (javaDateTime, formatType = "YYYY-MM-DD HH:mm") => {
    const localTime = moment.utc(javaDateTime).toDate();
    return moment(localTime).format(formatType);
};

export const formatDateToBackendUTCString = momentDate => {
    return moment(momentDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
};
/** Note: This is in the test suite */
/**
 * @deprecated import from decimalUtils.roundNumber instead
 */
export const roundNumber = number => Math.round(number * 100) / 100;

export const convertMomentDayNoToSwe = momentDayNo => {
    const dod = swedishDayOfWeek.find(day => day.momentDayNo === momentDayNo);
    return dod ? dod.dayOfWeek : "";
};

export const convertDayOfWeekToSwe = dayOfWeek => {
    const dod = swedishDayOfWeek.find(day => day.dayOfWeek === dayOfWeek);
    return dod ? dod.swe : "";
};

export const getNotUsedDayOfWeek = currentList => {
    return _.differenceBy(swedishDayOfWeek, currentList, "dayOfWeek");
};

// numberformat will return real minus character instead of dash minus  /** Note: This is in the test suite */
/**
 * @deprecated import from priceStringUtils.formatSwedishFinancialNumbers instead
 */
export const formatSwedishFinancialNumbers = number =>
    new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK" }).format(number).replace("−", "-");

export const printModificatonNames = (mods, name) => {
    const sizeName = mods.sizes && mods.sizes.length > 0 && mods.sizes[0].name ? mods.sizes[0].name : "";
    const flavourName = mods.flavours && mods.flavours.length > 0 && mods.flavours[0].name ? mods.flavours[0].name : "";
    return `${sizeName} ${flavourName}`.trim() + ` ${name}`;
};

export const hasModifications = mods => {
    return mods && ((mods.sizes && mods.sizes.length > 0) || (mods.flavours && mods.flavours.length > 0));
};

export const printProductName = (prod, bundleItemFontSize) => {
    let productName = "";
    if (hasModifications(prod.modifications)) {
        productName = printModificatonNames(prod.modifications, prod.name);
    } else if (!!prod.selectedBundleProductItems && prod.selectedBundleProductItems.length > 0) {
        productName = (
            <p>
                <span>
                    {prod.name}
                    <br />
                </span>
                {prod.selectedBundleProductItems.map((bundleItem, index) => {
                    const bundleItemName = hasModifications(bundleItem.modifications)
                        ? printModificatonNames(bundleItem.modifications, bundleItem.name)
                        : bundleItem.name;
                    return (
                        <span
                            style={{ fontSize: `${bundleItemFontSize}em`, marginLeft: "5px" }}
                            key={bundleItem.name + index}
                        >
                            {bundleItemName}
                            <br />
                        </span>
                    );
                })}
            </p>
        );
    } else {
        productName = prod.name.trim();
    }
    return productName;
};

export const truncateString = (string, limit) => {
    const dots = "...";
    if (string.length > limit) {
        string = string.substring(0, limit) + dots;
    }

    return string;
};
