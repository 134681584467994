import { Languagekey, TranslateFunc } from "Providers";

type ReactTableProps = {
    previousText: string;
    nextText: string;
    noDataText: string;
    pageText: string;
    ofText: string;
    rowsText: string;
};

const reactTableProperties: Array<keyof ReactTableProps> = [
    "previousText",
    "nextText",
    "noDataText",
    "pageText",
    "ofText",
    "rowsText"
];

export const filterCaseInsensitiveReactTable = ({ id, value }: { id: any; value: any }, row: any) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

export const translateReactTableProperties = (translationFunc: TranslateFunc) => {
    return reactTableProperties.reduce((props: ReactTableProps, propName: string) => {
        Reflect.set(props, propName, translationFunc(propName as Languagekey));
        return props;
    }, {} as ReactTableProps);
};

export const filterNumberReactTable = ({ id, value }: { id: any; value: any }, row: any) =>
    row[id] ? row[id].toString().includes(value.toString()) : true;


export const filterProductSalesTable = ({ id, value }: { id: any; value: any }, row: any) => {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
};
