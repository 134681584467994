import swal from "sweetalert2";

export const successNotification = (title, timer, text, allowOutsideClick = true) => {
    return swal({
        title: title,
        text: text,
        timer: timer,
        position: "center",
        type: "success",
        showConfirmButton: false,
        heightAuto: false,
        allowOutsideClick
    });
};

export const errorNotification = (title, text, timer, showConfirmButton = true, allowOutsideClick = true) => {
    return swal({
        title: title,
        text: text,
        timer,
        position: "center",
        type: "error",
        heightAuto: false,
        showConfirmButton,
        allowOutsideClick
    });
};

export const confirmNotificationWithLoad = async (
    title,
    text,
    confirmButtonText = "Ja, Ta bort",
    confirmButtonColor = "#d33",
    type = "warning",
    position = "center",
    showCloseButton = "true",
    allowOutsideClick = false,
    functionToExecute
) => {
    return await swal({
        title,
        text,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        showCloseButton,
        allowOutsideClick,
        heightAuto: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            return functionToExecute();
        }
    });
};

export const confirmNotification = async (
    title,
    text,
    confirmButtonText = "Ja, Ta bort",
    confirmButtonColor = "#d33",
    type = "warning",
    position = "center",
    showCloseButton = "true",
    allowOutsideClick = false,
    html = ""
) => {
    return await swal({
        title,
        html,
        text,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        showCloseButton,
        allowOutsideClick,
        heightAuto: false
    });
};
const inputOptions = new Promise(resolve => {
    setTimeout(() => {
        resolve({
            0: "Ingen tid",
            30: "30 min",
            45: "45 min",
            60: "60 min",
            90: "90 min"
        });
    }, 2000);
});

export const selectAndConfirmOrCancelNotification = async (
    title,
    text,
    input = "radio",
    confirmButtonText = "Ja",
    confirmButtonColor = "#d33",
    cancelButtonText = "Avbryt",
    type = "warning",
    position = "center",
    showCloseButton = "false",
    showCancelButton = "true",
    cancelButtonColor = "#d33",
    reverseButtons = false,
    html = "",
    width = 551
) => {
    return await swal({
        title,
        html,
        text,
        input,
        inputOptions: inputOptions,
        inputValidator: value => {
            return !value && "Du måste välja något!";
        },
        input,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        cancelButtonColor,
        heightAuto: false,
        reverseButtons,
        width
    });
};

export const confirmOrCancelNotification = async (
    title,
    text,
    confirmButtonText = "Ja",
    confirmButtonColor = "#d33",
    cancelButtonText = "Avbryt",
    type = "warning",
    position = "center",
    showCloseButton = "false",
    showCancelButton = "true",
    cancelButtonColor = "#d33",
    reverseButtons = false,
    html = ""
) => {
    return await swal({
        title,
        html,
        text,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        cancelButtonColor,
        heightAuto: false,
        reverseButtons
    });
};

export const confirmOrCancelNotificationWithRadio = async (
    title,
    text,
    inputOptions,
    inputValue,
    confirmButtonText = "Ja",
    confirmButtonColor = "#d33",
    cancelButtonText = "Avbryt",
    cancelButtonColor = "#d33",
    type = "warning",
    position = "center",
    showCloseButton = "false",
    showCancelButton = "true",
    reverseButtons = false,
    html = "",
    input = "radio"
) => {
    return await swal({
        title,
        html,
        text,
        inputOptions,
        inputValue,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        cancelButtonColor,
        heightAuto: false,
        reverseButtons,
        input
    });
};

export const autoCloseTimerNotification = async (title, text, duration, handleClose) => {
    let timerInterval;
    return await swal({
        title,
        html: text,
        timer: duration,
        onOpen: () => {
            swal.showLoading();
            timerInterval = setInterval(() => {
                const content = swal.getContent();
                if (!content) {
                    clearInterval(timerInterval);
                } else {
                    content.querySelector("strong").textContent = Math.ceil(swal.getTimerLeft() / 1000);
                }
            }, 100);
        },
        onAfterClose: () => {
            clearInterval(timerInterval);
        },
        onClose: () => {
            clearInterval(timerInterval);
        },
        heightAuto: false
    }).then(result => {
        if (result.dismiss === swal.DismissReason.timer) {
            // Note: Wonder if the the idle timer kicks in at the StartMenu
            // because we are unmounting too "quick" and the transition
            // component doesn't unmount the MenuTabs component which leads
            // to the idle timer hasn't recognised the unmount
            // setTimeout(() => handleClose(), 500);
            handleClose();
        }
    });
};

export const getInputFieldInput = async (
    title,
    text,
    confirmButtonText = "Ja",
    confirmButtonColor = "#d33",
    cancelButtonText = "Avbryt",
    cancelButtonColor = "#d33",
    type = "info",
    position = "center",
    showCloseButton = false,
    showCancelButton = true,
    reverseButtons = false,
    input = "text"
) => {
    return await swal({
        title,
        text,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        cancelButtonColor,
        heightAuto: false,
        reverseButtons,
        input,
        inputAttributes: {
            autocapitalize: "off"
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
    });
};
