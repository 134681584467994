import React from "react";
import { FaConciergeBell } from "@react-icons/all-files/fa/FaConciergeBell";
import { FaCashRegister } from "@react-icons/all-files/fa/FaCashRegister";

import { Box, Image } from "Atoms";
import { ThirdPartyDeliveryType, ONLINE_SERVICES, PosTypes } from "Constants";
import { WoltLogoPng, BoltLogoPng } from "Assets";

export const ServiceIconForOrderHistory = orderType => {
    switch (orderType) {
        case ONLINE_SERVICES.WEB:
            return (
                <Box color="newPrimary" lineHeight="1.7rem" fontSize="1.8rem" fontFamily="qopla">
                    Q
                </Box>
            );
        case ThirdPartyDeliveryType.FOODORA:
            return <Box as={FaConciergeBell} color="#c60d5c" size="1.5rem" />;
        case ThirdPartyDeliveryType.UBEREATS:
            return (
                <Box color="#3acb72" lineHeight="1rem" fontSize="2.5rem" fontWeight="900">
                    u
                </Box>
            );
        case ThirdPartyDeliveryType.WOLT:
            return <Image src={WoltLogoPng} width="2rem" height="1.5rem" />;
        case PosTypes.POS:
            return <Box as={FaCashRegister} color="black" size="1.5rem" />;
        case ThirdPartyDeliveryType.BOLT:
            return <Image src={BoltLogoPng} width="2rem" height="1.5rem" />;
    }
};
