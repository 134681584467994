import { useInterval } from "CoreHooks";
import { useEffect } from "react";

const Timer = ({ doFunction, intervalTime, runFirstMount = true }) => {
    useEffect(() => {
        runFirstMount && doFunction();
    }, []);
    useInterval(doFunction, intervalTime);

    return null;
};

export default Timer;
