import { capitalize } from "lodash";

import { calculateMenuProductPrice, roundNumber, singleModificationAddonPrice } from "TempUtils";
import { getModificationLengths, modificationsHasPrice, findLowestModificationPrice } from "TempUtils/TextFormat";
import { getBundleFromPrice } from "../components/poses/pos/utils/priceUtils";
import { getActiveLanguage } from "../components/express-pos/utils/languageUtils";

export const bundleItemPriceText = (price, quantity = 1, activeLanguage, translate) => {
    if (price) {
        return `${price > 0 ? "+ " : ""}${price * quantity}kr`;
    } else {
        return translate("included", activeLanguage);
    }
};

/**
 * Only used in ExpressPOS
 */
export const getPriceText = (menuProduct, selectedProduct, refProductHashMap = null, translate) => {
    const modHasPrice = selectedProduct.modifications && modificationsHasPrice(selectedProduct.modifications);
    const modificationsToUse = menuProduct.modifications ? menuProduct.modifications : selectedProduct.modifications;
    const activeLanguage = getActiveLanguage();

    const price = roundNumber(calculateMenuProductPrice(menuProduct)); // Gets the correct price based on the menuProduct
    let priceText = menuProduct.selectedBundleProd || modHasPrice ? null : `${price} kr`;
    const { sizes: numOfSizes, flavours: numOfFlavours } = getModificationLengths(modificationsToUse);
    if (refProductHashMap && menuProduct.refBundleProduct && menuProduct.refBundleProduct.defaultPrice === 0) {
        const bundlePrice = getBundleFromPrice(menuProduct, refProductHashMap);
        priceText = `${capitalize(translate("from", activeLanguage))} ${bundlePrice} kr`;
    } else if (numOfSizes + numOfFlavours === 1 && !menuProduct.selectedBundleProd) {
        if (modHasPrice) {
            const modPrice = numOfSizes > 0 ? modificationsToUse.sizes[0].price : modificationsToUse.flavours[0].price;
            priceText = `${modPrice} kr`;
        } else {
            const addonPrice = singleModificationAddonPrice(modificationsToUse);
            if (addonPrice > 0) {
                priceText = `${price + addonPrice} kr`;
            }
        }
    } else if (numOfSizes + numOfFlavours === 1 && menuProduct.selectedBundleProd) {
        const addonPrice = singleModificationAddonPrice(modificationsToUse);
        if (addonPrice > 0) {
            priceText = `+ ${addonPrice} kr`;
        }
    } else if (numOfSizes + numOfFlavours > 1 && !menuProduct.selectedBundleProd) {
        const lowestModificationPrice =
            (modHasPrice ? 0 : price) + findLowestModificationPrice(selectedProduct.modifications);
        priceText = `${capitalize(translate("from", activeLanguage))} ${lowestModificationPrice} kr`;
    }
    return priceText;
};

export const getBundleModAddonPriceText = modifications => {
    let priceText = null;
    const { sizes, flavours } = getModificationLengths(modifications);
    if (sizes + flavours === 1) {
        const addon = singleModificationAddonPrice(modifications);
        priceText = addon !== 0 ? bundleItemPriceText(addon) : null;
    } else if (sizes <= 1 && flavours <= 1) {
        const hasSizeAddonPrice = !!sizes && !!modifications.sizes[0].addonPrice;
        const hasFlavoursAddonPrice = !!flavours && !!modifications.flavours[0].addonPrice;
        const addonPrice =
            (hasSizeAddonPrice ? modifications.sizes[0].addonPrice : 0) +
            (hasFlavoursAddonPrice ? modifications.flavours[0].addonPrice : 0);
        priceText = addonPrice !== 0 ? bundleItemPriceText(addonPrice) : null;
    }
    return priceText;
};
