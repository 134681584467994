/*
 * File for all price calculations
 */

// START OF CALCULATIONS FOR BUNDLE ITEMS
const emptyModObj = {
    sizes: { name: null, price: null, addonPrice: null },
    flavours: { name: null, price: null, addonPrice: null }
};

// WARNING: Don't use this for new code, use instead:
// import { calculateBundleModsPrice } from "PriceUtils";
// calculateBundleModsPrice([...bundleItem])
// or
// import { calculateTotalModPrice } from "PriceUtils";
// calculateTotalModPrice(bundleItem.modifications)
export const calculateBundleModPrice = bundleItem => {
    if (!bundleItem.modifications) bundleItem.modifications = emptyModObj;

    const { sizes, flavours } = bundleItem.modifications;
    const sizePrice = sizes ? sizes.addonPrice : 0;
    const flavourPrice = flavours ? flavours.addonPrice : 0;
    return sizePrice + flavourPrice;
};
// END OF CALCULATIONS FOR BUNDLE ITEMS

// WARNING: Don't use this for new code, use instead:
// import { getVatAmount } from "PriceUtils";
/**
 * @deprecated import from "PriceUtils" (priceCalculationUtils.ts) instead
 */
export const getVatAmount = (rate, total) => {
    const vatRateDivider = 1 + rate / 100;
    return parseFloat((total - total / vatRateDivider).toFixed(2));
};
