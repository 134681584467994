import { calculateBundleModsPrice } from "PriceUtils";
import { getTotalAddonsPrice } from "../../../utils/newPriceHelpers";

// WARNING: Don't use this for new code, use instead:
// import { calculateTotalModPrice } from "PriceUtils";
export const getSelectedModificationPrice = modifications => {
    const sizes = Array.isArray(modifications.sizes) ? modifications.sizes[0] : modifications.sizes;
    const flavours = Array.isArray(modifications.flavours) ? modifications.flavours[0] : modifications.flavours;
    const s = sizes ? sizes.price + sizes.addonPrice : 0;
    const f = flavours ? flavours.price + flavours.addonPrice : 0;

    return s + f;
};

// WARNING: Don't use this for new code, use instead:
// import { calculateBundleAddonPrice } from "PriceUtils";
export const calculateBundleAddonPrice = selectedBundleItems => {
    return selectedBundleItems.reduce((total, { addons }) => (addons ? total + getTotalAddonsPrice(addons) : 0), 0);
};

// WARNING: Don't use this for new code, use instead:
// import { calculateMenuProductOrBundlePrice } from "PriceUtils";
export const getPrice = (product, selectedModifications, selectedBundleItems, addons) => {
    const addonsPrice = addons ? getTotalAddonsPrice(addons) : 0;

    // first check if its a bundle product?
    if (!!product.refBundleProduct) {
        // Need to handle bundleProduct with selected bundle items
        const menuBundleBasePrice = getBasePrice(product.refBundleProduct, product);
        if (!!selectedBundleItems) {
            const bundleItemsAddonsPrice = calculateBundleAddonPrice(selectedBundleItems);

            return (
                calculateBundleModsPrice(selectedBundleItems) +
                menuBundleBasePrice +
                bundleItemsAddonsPrice +
                addonsPrice
            );
        }
        return menuBundleBasePrice + addonsPrice;
    }

    if (selectedModifications) {
        const modificationPrice = getSelectedModificationPrice(selectedModifications);
        const modHasPrice = selectedModHasPrice(selectedModifications);
        const productBasePrice = modHasPrice ? 0 : getProductBasePrice(product);
        return modificationPrice + productBasePrice + addonsPrice;
    } else {
        return getProductBasePrice(product) + addonsPrice;
    }
};

const selectedModHasPrice = selectedModification => {
    const { sizes, flavours } = selectedModification;
    return (sizes && sizes.price > 0) || (flavours && flavours.price > 0);
};

// WARNING: Don't use this for new code, use instead:
// import { getMenuProductPrice } from "PriceUtils";
const getBasePrice = (product, menuProduct) => {
    if (!!menuProduct.price && menuProduct.price !== 0) {
        return menuProduct.price;
    } else {
        return product.defaultPrice;
    }
};

// WARNING: Don't use this for new code, use instead:
// import { getMenuProductPrice } from "PriceUtils";
// or
// import { getMenuBundleProductPrice } from "PriceUtils";
export const getProductBasePrice = menuProduct => {
    const { refBundleProduct, refProduct } = menuProduct;
    return refBundleProduct ? getBasePrice(refBundleProduct, menuProduct) : getBasePrice(refProduct, menuProduct);
};

export const getTotalNetPrice = (totalPrice, vatAmount) => {
    return parseFloat((totalPrice - vatAmount).toFixed(2));
};
