import React, { useState } from "react";
import propTypes from "prop-types";
import pose from "react-pose";
import { useSpring, animated, config } from "react-spring";
import { isFunction } from "lodash";

import { Button } from "Atoms";

const AnimatedButton = animated(Button);

export const SpringButton = ({ children, customOnClick, style, ...restProps }) => {
    const [pressed, setPressed] = useState(false);

    const { scale } = useSpring({
        native: true,
        from: { scale: 1 },
        to: { scale: pressed ? 0.9 : 1 },
        config: {
            wobly: config.wobbly,
            mass: 0.4,
            tension: 650
        }
    });

    return (
        <AnimatedButton
            _hover={undefined}
            transition={undefined}
            style={{ ...style, transform: scale.interpolate(scale => `scale(${scale})`) }}
            onMouseDown={() => setPressed(true)}
            onClick={e => {
                isFunction(customOnClick) && customOnClick(e);
                setPressed(false);
            }}
            onMouseLeave={() => setPressed(false)}
            height={undefined}
            {...restProps}
        >
            {children}
        </AnimatedButton>
    );
};

SpringButton.propTypes = {
    className: propTypes.string,
    customOnClick: propTypes.func,
    disabled: propTypes.bool
};

// BELOW HERE WILL BE MIGRATED TO REACT-SPRING

export const ScalableDiv = pose.div({
    pressable: true,
    init: { scale: 1 },
    scaled: {
        scale: 1.4,
        transition: {
            duration: 200
        }
    }
});

export const ScaledButton = pose.button({
    pressable: true,
    init: {
        scale: ({ initalScale }) => initalScale
    },
    press: {
        scale: ({ pressedScale }) => pressedScale
    }
});

export const ScaledDiv = pose.div({
    pressable: true,
    init: {
        scale: ({ initialScale }) => initialScale
    },
    press: {
        scale: ({ pressedScale }) => pressedScale
    }
});
