import React from "react";
import * as axios from "axios";
import { escape } from "lodash";
import { formatDateToLocal, formatSwedishFinancialNumbers } from "./TextFormat";
import { PaymentMethod, ReceiptType, printerConstants, GLOBAL_TIP_PRODUCT_NAME } from "Constants";
import Timer from "./Timer";
import moment from "moment";
import { isVariablePriceType } from "ProductUtils";
import { getUnitStringForPriceType } from "PriceUtils";
import { swedishPaymentMethod } from "Utils";
import { EatingPreference } from "Types";
import { emojiRegExp, pickupQrCode } from "./AdvanceReceipt";

export const bundleItemsToPrint = product => {
    const bundleText = product.selectedBundleProductItems.reduce((bundleText, bundleItem) => {
        let size = "",
            flavour = "";
        if (bundleItem.modifications) {
            size = getSelectedModificationName(bundleItem.modifications.sizes);
            flavour = getSelectedModificationName(bundleItem.modifications.flavours);
        }
        const productName = `${size} ${flavour} ${bundleItem.name}`
            .trim()
            .replace("  ", " ")
            .replaceAll("&", "&amp;")
            .substring(0, printerConstants.NO_CHARACTERS_PER_LINE - 5)
            .replace(emojiRegExp, "");

        bundleText += `<text>    -${productName}&#10;</text>`;
        if (bundleItem.addons) {
            bundleText += addonsToXml(bundleItem.addons, 6);
        }
        return bundleText;
    }, "");

    return bundleText;
};

export const getSelectedModificationName = modification => {
    const modName = modification && modification.length > 0 ? modification[0].name : "";
    return modName ? modName : "";
};

export const commentToXml = (comment, indent = 3) => {
    const commentRowSplit = comment.split(/\r?\n/);
    return commentRowSplit.reduce((acc, rowComment) => {
        if (rowComment === "") return acc;
        acc += `<text>${" ".repeat(indent + 2)}${escape(rowComment)}&#10;</text>`;
        return acc;
    }, `<text>${" ".repeat(indent)}Kommentar:&#10;</text>`);
};

export const addonsToXml = (addons, indent = 3) => {
    if (!addons) return "";
    else {
        return addons.reduce((addonText, addon) => {
            const addonName = addon.name.trim().replace("  ", " ").replaceAll("&", "&amp;").replace(emojiRegExp, "");
            const text = `${" ".repeat(indent)}+ ${addon.quantity}x ${addonName}`;
            const operator = addon.price > 0 ? "+" : "";
            const priceText = addon.price !== 0 ? operator + addon.price.toFixed(2) : "-";
            const spaces =
                printerConstants.NO_CHARACTERS_PER_LINE -
                ((text.length + priceText.length) % printerConstants.NO_CHARACTERS_PER_LINE);

            addonText += `<text>${text}${" ".repeat(spaces)}${priceText}&#10;</text>`;
            return addonText;
        }, "");
    }
};

export const getQuantityOrWeightLabel = orderProduct => {
    const priceType = orderProduct.priceType;
    const hasVariablePriceType = isVariablePriceType(priceType);
    const unitString = getUnitStringForPriceType(priceType);
    if (hasVariablePriceType) {
        return `${orderProduct.weight} ${unitString} `;
    } else {
        return `${orderProduct.quantity} x `;
    }
};

export const discountNames = (combinedDiscounts, indent) => {
    const charactersPerLine = printerConstants.NO_CHARACTERS_PER_LINE;

    /** It is possible that the discount for combo discount is applied 2 so a set is more better */
    const namesList = combinedDiscounts?.reduce((names, discount) => {
        return names.add(discount.name);
    }, new Set());

    const discountIndent = " ".repeat(indent + 4);

    return namesList
        ? Array.from(namesList).reduce((productXml, name) => {
              const discountNameLength = name.length;
              const spacingLeft = charactersPerLine - discountIndent - discountNameLength;
              productXml += `<text>${discountIndent}- ${escape(name)}${"".repeat(spacingLeft)}&#10;</text>`;
              return productXml;
          }, "")
        : "";
};

const orderProductsToXml = orderProducts => {
    const orderProductsWithoutTip = orderProducts.filter(orderProduct => orderProduct.name !== GLOBAL_TIP_PRODUCT_NAME);

    return orderProductsWithoutTip.reduce((productXml, product) => {
        const quantityOrWeightLabel = getQuantityOrWeightLabel(product);
        let size = "",
            flavour = "";
        if (product.modifications) {
            size = getSelectedModificationName(product.modifications.sizes);
            flavour = getSelectedModificationName(product.modifications.flavours);
        }
        let productName = `${size} ${flavour} ${product.name}`.trim().replace("  ", " ").replace(emojiRegExp, "");

        const totalPrice = formatSwedishFinancialNumbers(product.totalPrice).replace("kr", "").trim();
        let noSpacesToAppend =
            printerConstants.NO_CHARACTERS_PER_LINE -
            productName.length -
            quantityOrWeightLabel.length -
            totalPrice.length;
        if (noSpacesToAppend < 1) {
            noSpacesToAppend = 1;
            productName = productName.substring(
                0,
                printerConstants.NO_CHARACTERS_PER_LINE - quantityOrWeightLabel.length - totalPrice.length - 1
            );
        }

        const productString = quantityOrWeightLabel + escape(productName) + " ".repeat(noSpacesToAppend) + totalPrice;
        productXml += `<text>${productString}&#10;</text>`;
        if (product.discountValue) {
            const discountValue = formatSwedishFinancialNumbers(-product.discountValue).replace("kr", "").trim();
            const indent = " ".repeat(quantityOrWeightLabel.length - 2);
            const discountSpacing = Math.max(
                printerConstants.NO_CHARACTERS_PER_LINE - discountValue.length - indent.length - 8,
                0
            );
            productXml += `<text>${indent}- Rabatt${" ".repeat(discountSpacing)}${discountValue}&#10;</text>`;
            productXml += discountNames(product.combinedDiscounts, indent);
        }
        if (product.selectedBundleProductItems) {
            productXml += bundleItemsToPrint(product);
        }
        if (product.addons) {
            productXml += addonsToXml(product.addons);
        }
        if (product.comment) {
            productXml += commentToXml(product.comment);
        }
        return productXml;
    }, "");
};

const totalDiscountToXml = discountMeta => {
    if (!discountMeta) return "";
    const originalPrice = formatSwedishFinancialNumbers(discountMeta.originalPrice);
    const originalPriceSpacing = Math.max(printerConstants.NO_CHARACTERS_PER_LINE - originalPrice.length - 12, 0);

    const totalDiscountAmount = formatSwedishFinancialNumbers(-discountMeta.totalDiscountValue);
    const spacePaddings = Math.max(
        printerConstants.NO_CHARACTERS_PER_LINE -
            ((13 + discountMeta.name.length + totalDiscountAmount.length) % printerConstants.NO_CHARACTERS_PER_LINE),
        0
    );
    return (
        `<text>   Ord pris:${" ".repeat(originalPriceSpacing)}${originalPrice}&#10;</text>` +
        `<text>   Rabatt (${escape(discountMeta.name)}):${" ".repeat(spacePaddings)}${totalDiscountAmount}&#10;</text>`
    );
};

export const totalAmountToXml = amount => {
    const totalAmount = formatSwedishFinancialNumbers(amount);
    const spacePaddings = Math.max(printerConstants.NO_CHARACTERS_PER_LINE_S2 - totalAmount.length - 8, 0);
    return `<text>Totalt ${" ".repeat(spacePaddings)} ${totalAmount}&#10;</text>`;
};

const tipAmountToXml = tip => {
    if (!tip) {
        return "";
    }
    const tipAmount = formatSwedishFinancialNumbers(tip);
    const tipText = `   Dricks: `;
    const spacePaddings = printerConstants.NO_CHARACTERS_PER_LINE - tipAmount.length - tipText.length;

    const tipString = tipText + " ".repeat(spacePaddings) + tipAmount;

    return `<text>${tipString}&#10;</text>`;
};

const cashChangeAndTotalPaymentToXml = (amount, change, paymentMethodAmounts) => {
    const isCashPayment = paymentMethodAmounts.every(({ paymentMethod }) => paymentMethod === PaymentMethod.CASH);

    if (!isCashPayment) return "";

    const tot = amount + change;

    const totalAmount = formatSwedishFinancialNumbers(Math.round(tot));
    const cashChange = formatSwedishFinancialNumbers(Math.round(change));

    const totalCashAmountSpacePadding = Math.max(printerConstants.NO_CHARACTERS_PER_LINE - totalAmount.length - 13, 0);
    const cashChangeSpacePadding = Math.max(printerConstants.NO_CHARACTERS_PER_LINE - cashChange.length - 10, 0);
    const cents = Math.round(tot) - tot;
    let centText = "";
    if (cents !== 0) {
        const centFormattedText = formatSwedishFinancialNumbers(cents);
        const centTextSpacing = Math.max(printerConstants.NO_CHARACTERS_PER_LINE - centFormattedText.length - 19, 0);
        centText = `<text>   Öresavrundning: ${" ".repeat(centTextSpacing)}${centFormattedText}&#10;</text>`;
    }
    return `
        <text>   Mottaget: ${" ".repeat(totalCashAmountSpacePadding)}${totalAmount}&#10;</text>
        <text>   Växel: ${" ".repeat(cashChangeSpacePadding)}${cashChange}&#10;</text>
        ${!!centText ? centText : ""}
    `;
};

const paymentMethodsToXml = paymentMethods => {
    return paymentMethods.reduce((paymentMethodXml, paymentMethod) => {
        const amountTotal =
            paymentMethod.paymentMethod === PaymentMethod.CASH
                ? Math.round(paymentMethod.amount)
                : paymentMethod.amount;
        const amount = formatSwedishFinancialNumbers(amountTotal);
        const method = `   ${swedishPaymentMethod[paymentMethod.paymentMethod].swe}:`;
        const noSpacesToAppend = printerConstants.NO_CHARACTERS_PER_LINE - amount.length - method.length;

        const paymentMethodString = method + " ".repeat(noSpacesToAppend) + amount;
        paymentMethodXml += `<text>${paymentMethodString}&#10;</text>`;
        return paymentMethodXml;
    }, "");
};

export const vatToXml = vatRates => {
    return vatRates.reduce((vatRateXml, vatRate) => {
        if (vatRate.amount > 0) {
            const amount = formatSwedishFinancialNumbers(vatRate.amount);
            const vat = `   Moms ${vatRate.vatRate.toFixed(1)}%:`;
            const noSpacesToAppend = printerConstants.NO_CHARACTERS_PER_LINE - amount.length - vat.length;

            const vatString = vat + " ".repeat(noSpacesToAppend) + amount;
            vatRateXml += `<text>${vatString}&#10;</text>`;
        }
        return vatRateXml;
    }, "");
};

export const heartBeatMessage = (ip, deviceName) => {
    const heartBeatXml = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        </epos-print>
        </s:Body>
        </s:Envelope>`;

    return axios({
        method: "post",
        url: `https://${ip}/cgi-bin/epos/service.cgi?devid=${deviceName}&timeout=3000`,
        data: heartBeatXml,
        timeout: 3000
    })
        .then(({ data }) => {
            return data.includes('success="true"');
        })
        .catch(() => {
            return false;
        });
};

export const HeartBeatReceiptPrinter = props => {
    const heartBeatXml = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        </epos-print>
        </s:Body>
        </s:Envelope>`;

    const { receiptPrinter } = props;
    return (
        <Timer
            intervalTime={30000}
            doFunction={() => {
                axios({
                    method: "post",
                    url: `https://${receiptPrinter.ip}/cgi-bin/epos/service.cgi?devid=${receiptPrinter.deviceName}&timeout=3000`,
                    data: heartBeatXml,
                    timeout: 3000
                })
                    .then(({ data }) => {
                        const connectedPrinter = data.includes('success="true"');
                        props.onHeartbeatChange(connectedPrinter);
                    })
                    .catch(() => {
                        props.onHeartbeatChange(false);
                    });
            }}
        />
    );
};

/** @deprecated , use instead: AdvanceReceipt.ts */
export const centerReceiptText = (text, fontSize = printerConstants.NO_CHARACTERS_PER_LINE) => {
    const receiptTextSpaces = " ".repeat(Math.floor(Math.max(fontSize - text.length, 0) / 2));

    const centeredText = `${receiptTextSpaces}${text}${receiptTextSpaces}`;

    return centeredText.length === fontSize ? centeredText : centeredText + " ";
};

const shouldOpenDrawer = orderPaymentMethods => {
    const hasCashPayment = orderPaymentMethods.some(op => op.paymentMethod === PaymentMethod.CASH);

    if (hasCashPayment) {
        return '<pulse drawer="drawer_1" time="pulse_100"/>';
    } else {
        return "";
    }
};

const receiptHeader = order => {
    const { receiptType, paymentMethodAmounts } = order;
    switch (receiptType) {
        case ReceiptType.PURCHASE:
            if (order.paymentMethodAmounts.some(p => p.paymentMethod === PaymentMethod.INVOICE)) {
                return `<text reverse="true" ul="false" em="false" color="color_1"/>
                    <text width="2" height="2"/>
                    <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                    <text>${centerReceiptText("FAKTURAKVITTO", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                    <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                    <text reverse="false" ul="false" em="false" color="color_1"/>
                    <text width="1" height="1"/>`;
            } else {
                return `<text>${centerReceiptText("Kvitto")}&#10;</text>`;
            }

        case ReceiptType.REFUND:
            return `<text>${centerReceiptText("Returkvitto")}&#10;</text>`;
        case ReceiptType.CASH_INVOICE:
            if (order.paymentMethodAmounts.some(p => p.paymentMethod === PaymentMethod.INVOICE)) {
                return `<text reverse="true" ul="false" em="false" color="color_1"/>
                    <text width="2" height="2"/>
                    <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                    <text>${centerReceiptText("FAKTURAKVITTO", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                    <text width="1" height="1"/>
                    <text linespc="0"/>
                    <text>${centerReceiptText("KONTANTFAKTURA")}&#10;</text>
                    <text>${centerReceiptText("Kontrollenhet ej tillgänglig")}&#10;</text>
                    <text>${centerReceiptText("Verifikationsnr: " + order.receiptNumber)}&#10;</text>
                    <text width="2" height="2"/>
                    <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                    <text reverse="false" ul="false" em="false" color="color_1"/>
                    <text linespc="30"/>
                    <text width="1" height="1"/>`;
            } else {
                return `<text reverse="true" ul="false" em="false" color="color_1"/>
            <text linespc="0"/>
            <text width="2" height="2"/>
            <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
            <text>${centerReceiptText("KONTANTFAKTURA", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
            <text width="1" height="1"/>
            <text>${centerReceiptText(
                "Verifikationsnr: " + order.receiptNumber,
                printerConstants.NO_CHARACTERS_PER_LINE
            )}&#10;</text>
            <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE)}&#10;</text>
            <text reverse="false" ul="false" em="false" color="color_1"/>
            <text linespc="30"/>
            <feed unit="30"/>`;
            }
        case ReceiptType.EMVREFUND:
            return `<text>${centerReceiptText("Retur vid systemfel")}&#10;</text>`;
        case ReceiptType.COPY:
            return `<text>${centerReceiptText("Kvittokopia")}&#10;</text>`;
        case ReceiptType.FAILED:
            const hasCardPayment = paymentMethodAmounts.some(
                payOption => payOption.paymentMethod === PaymentMethod.CARD
            );
            const cardPaymentError = hasCardPayment
                ? `<text>${centerReceiptText("Pengar har dragits men inget giltligt")}&#10;</text>
            <text>${centerReceiptText("kvitto har skrivits ut. Kontakta personal")}&#10;</text>
            <text>${centerReceiptText("för att skriva ut kvittot")}&#10;</text>
            <text>${centerReceiptText("och lägga beställningen.")}&#10;</text>

            <feed unit="30"/>`
                : "";

            const refNoText = "Referensnummer: " + order.receiptNumber;
            const dateText = "Datum: " + formatDateToLocal(order.purchaseDate);
            const sumText = "Summa: " + formatSwedishFinancialNumbers(order.totalAmount);
            return `<text reverse="true" ul="false" em="false" color="color_1"/>
            <text width="2" height="2"/>
            <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
            <text>${centerReceiptText("MISSLYCKAD ORDER ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
            <text>${centerReceiptText("PENGAR HAR DRAGITS", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
            <text>${centerReceiptText("KONTAKTA PERSONALEN", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
            <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
            <text reverse="false" ul="false" em="false" color="color_1"/>
            <text width="1" height="1"/>
            <feed unit="30"/>
            <text>${centerReceiptText(dateText)}&#10;</text>
            <text>${centerReceiptText(refNoText)}&#10;</text>
            <text>${centerReceiptText(sumText)}&#10;</text>
            <feed unit="30"/>
            ${cardPaymentError}
            `;
        default:
            break;
    }
};

const refundFields = buyersCopy => {
    const fields = buyersCopy
        ? "<text>Köparens namn:____________________________&#10;</text>" +
          '<feed unit="30"/>' +
          "<text>Köparens tel nr:__________________________&#10;</text>"
        : "<text>Säljarens namn:___________________________&#10;</text>";

    const whosCopy = buyersCopy
        ? "<text>_____________Kundens uppgifter____________&#10;</text>"
        : "<text>____________Säljarens uppgifter___________&#10;</text>";

    return '<feed unit="30"/>' + whosCopy + '<feed unit="30"/>' + fields + '<feed unit="60"/>';
};

const signatureFields = buyersCopy => {
    return buyersCopy
        ? ""
        : '<feed unit="30"/>' +
              "<text>__________________________________________&#10;</text>" +
              '<feed unit="30"/>' +
              "<text>Underskrift:____________________________&#10;</text>" +
              '<feed unit="30"/>' +
              "<text>Personnummer:__________________________&#10;</text>" +
              '<feed unit="30"/>';
};

const cashInvoiceFields = () => {
    return (
        '<feed unit="30"/>' +
        "<text>____________Kundens uppgifter_____________&#10;</text>" +
        '<feed unit="30"/>' +
        "<text>Namn:___________________________________&#10;</text>" +
        '<feed unit="30"/>' +
        "<text>Adress:_________________________________&#10;</text>" +
        '<feed unit="30"/>'
    );
};

const orderNumberBox = (orderNo, takeAway, receiptType) => {
    if (receiptType === ReceiptType.PURCHASE || receiptType === ReceiptType.CASH_INVOICE) {
        const takeAwayText = takeAway ? "Takeaway" : "Äta här ";
        return `<text reverse="true" ul="false" em="false" color="color_1"/>
                <text linespc="0"/>
                <text width="2" height="2"/>
                <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                <text dw="true" dh="true"/>
                <text>${centerReceiptText(orderNo.toString(), printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                <text dw="false" dh="false"/>
                <text width="1" height="1"/>
                <text>${centerReceiptText("Ordernummer ")}&#10;</text>
                <text>${centerReceiptText(takeAwayText)}&#10;</text>
                <text>${centerReceiptText("  ")}&#10;</text>
                <text reverse="false" ul="false" em="false" color="color_1"/>
                <feed unit="30"/>`;
    } else {
        return "";
    }
};

export const openDrawer = receiptPrinter => {
    const drawerXml = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <pulse drawer="drawer_1" time="pulse_100"/>
        </epos-print>
        </s:Body>
        </s:Envelope>`;
    axios.post(
        `https://${receiptPrinter.ip}/cgi-bin/epos/service.cgi?devid=${receiptPrinter.deviceName}&timeout=10000`,
        drawerXml
    );
};

export const receiptText = ({ receiptType, receiptNumber }) => {
    switch (receiptType) {
        case ReceiptType.COPY:
        case ReceiptType.PURCHASE:
        case ReceiptType.REFUND:
            return `<text>Kvitto #${receiptNumber}&#10;</text>`;
        case ReceiptType.CASH_INVOICE:
            return `<text>Verifikationsnummer #${receiptNumber}&#10;</text>`;
        default:
            return "";
    }
};

const creditCardInfoToXml = creditCardInfo => {
    if (creditCardInfo) {
        const spaceBetweenLength = Math.max(
            printerConstants.NO_CHARACTERS_PER_LINE -
                creditCardInfo.cardIssuer.length -
                creditCardInfo.cardNumber.length,
            0
        );
        const spaces = " ".repeat(spaceBetweenLength);
        return `<text>${creditCardInfo.cardIssuer}${spaces}${creditCardInfo.cardNumber}&#10;</text>`;
    } else {
        return "";
    }
};

const controlUnitInfoTXml = (controlUnitNumber, receiptType) => {
    const noControlUnitValues = [ReceiptType.FAILED, ReceiptType.EMVREFUND, ReceiptType.CASH_INVOICE];
    const spaceBetweenLength = Math.max(printerConstants.NO_CHARACTERS_PER_LINE - controlUnitNumber?.length - 7, 0);
    return !noControlUnitValues.includes(receiptType)
        ? `<text>K.enhet${" ".repeat(spaceBetweenLength)}${controlUnitNumber}&#10;</text>`
        : "";
};

const orderOnlineMessage = restaurantOnlineOrderUrl => {
    return `<text reverse="false" ul="false" em="true" color="color_1"/>
        <text>${centerReceiptText("↓↓↓ Beställ Online ↓↓↓")}&#10;</text>
        <text align="center"/>
        <symbol type="qrcode_model_2" level="default" width="5" height="5" size="0">${escape(
            restaurantOnlineOrderUrl
        )}</symbol>
        <text>${centerReceiptText("↑↑↑ Beställ Online ↑↑↑")}&#10;</text>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text align="left"/>
        <feed unit="50"/>`;
};

export const receiptShopName = shopName => {
    const shopNameParts = shopName.split(" ");
    const countChar = shopName.length;
    if (countChar > printerConstants.NO_CHARACTERS_PER_LINE_S2) {
        let text = "";
        let middleOfLine = printerConstants.NO_CHARACTERS_PER_LINE_S2 / 2;
        let subStringStartIdx = 0;
        let subStringEndIdx = 0;
        shopNameParts.forEach(word => {
            subStringEndIdx += word.length + 1;
            if (subStringEndIdx > middleOfLine) {
                subStringEndIdx -= word.length + 1;
                const subString = shopName.substr(subStringStartIdx, subStringEndIdx);
                text += `<text>${escape(subString.toUpperCase())}&#10;</text><feed unit="10"/>`;
                middleOfLine += printerConstants.NO_CHARACTERS_PER_LINE_S2 / 2;
                subStringStartIdx += subStringEndIdx;
                subStringEndIdx += word.length + 1;
            }
        });
        return text;
    } else {
        return `<text>${escape(shopName.toUpperCase())}&#10;</text><feed unit="10"/>`;
    }
};

const receiptBody = (
    order,
    isReturnReceipt,
    isSignatureReceipt,
    cashChange,
    restaurantOnlineOrderUrl,
    pickupScreenUrl
) => {
    if (order.receiptType === ReceiptType.FAILED) {
        return `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <text smooth="true"/>
        ${receiptHeader(order)}
        <feed unit="10"/>
        <text width="3" height="3"/>
        <text dw="true" dh="true"/>
        <text align="center" />
        <feed unit="30"/>
        <cut type="feed"/>
        </epos-print>
        </s:Body>
        </s:Envelope>`;
    } else {
        const orderProductsXml = orderProductsToXml(order.orderProducts);
        const totalAmountXml = totalAmountToXml(order.totalAmount);
        const discountXml = totalDiscountToXml(order.discountMeta);
        const tipAmountXml = tipAmountToXml(order.tip);
        const cashChangeAndCashTotal = isReturnReceipt
            ? ""
            : cashChangeAndTotalPaymentToXml(order.totalAmount, cashChange, order.paymentMethodAmounts);
        const creditCardInfo = creditCardInfoToXml(order.creditCardInfo);
        const controlUnitNumber = controlUnitInfoTXml(
            order.orderControlUnitValues.controlUnitNumber,
            order.receiptType
        );
        const paymentMethodsXml = paymentMethodsToXml(order.paymentMethodAmounts);
        const vatRateXml = vatToXml(order.vatRatesAndAmounts);
        const openDrawer = shouldOpenDrawer(order.paymentMethodAmounts);

        const receiptTextXml = receiptText(order);

        const phoneNumberString = !!order.contactInformation.phoneNumber
            ? `<text>Tel: ${order.contactInformation.phoneNumber}&#10;</text>`
            : "";
        const mailString = !!order.contactInformation.email
            ? `<text>Email: ${order.contactInformation.email}&#10;</text>`
            : "";
        const isTakeAway = order.eatingOption === EatingPreference.TAKE_AWAY;
        const receiptXml = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <text smooth="true"/>
        ${receiptHeader(order)}
        <feed unit="10"/>
        <text width="3" height="3"/>
        <text dw="true" dh="true"/>
        <text align="center" />
        ${receiptShopName(order.shopName)}
        <text width="1" height="1"/>
        <text>Org.nr: ${order.organisationNumber}&#10;</text>
        <text>${escape(order.contactInformation.addressLine)}&#10;</text>
        <text>${order.contactInformation.postCode}, ${order.contactInformation.city}&#10;</text>
        ${phoneNumberString}
        ${mailString}
        <text>______________________&#10;</text>
        <feed unit="20"/>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text>${formatDateToLocal(order.purchaseDate)}&#10;</text>
        ${receiptTextXml}
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text>${order.posName}&#10;</text>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text>__________________________________________&#10;</text>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text align="left" />
        <feed unit="20"/>
        ${orderProductsXml}
        <text>__________________________________________&#10;</text>
        <feed unit="15"/>
        <text width="2" height="2"/>
        ${totalAmountXml}
        <feed unit="5"/>
        <text width="1" height="1"/>
        ${tipAmountXml}
        ${discountXml}
        ${paymentMethodsXml}
        ${vatRateXml}
        <feed unit="15"/>
        <text width="1" height="1"/>
        ${cashChangeAndCashTotal}
        <feed unit="10"/>
        ${creditCardInfo}
        ${controlUnitNumber}
        ${
            isReturnReceipt ||
            isSignatureReceipt ||
            (order.orderControlUnitValues.failedRequest && order.receiptType !== ReceiptType.FAILED) ||
            order.paymentMethodAmounts.some(p => p.paymentMethod === PaymentMethod.INVOICE)
                ? printerConstants.EXTRA_RECEIPT_REPLACE_STRING
                : ""
        }
        <feed unit="50"/>
        ${order.driveThrough ? "" : orderNumberBox(order.orderNo, isTakeAway, order.receiptType)}
        ${getQrCodeToShow(pickupScreenUrl, restaurantOnlineOrderUrl)}
        <text>                          powered by qopla</text>
        ${openDrawer}
        <feed unit="30"/>
        <cut type="feed"/>
        </epos-print>
        </s:Body>
        </s:Envelope>`;
        return receiptXml;
    }
};

const getQrCodeToShow = (pickupScreenUrl, restaurantOnlineOrderUrl) => {
    if (!!pickupScreenUrl) {
        return pickupQrCode(pickupScreenUrl);
    } else if (restaurantOnlineOrderUrl) {
        return orderOnlineMessage(restaurantOnlineOrderUrl);
    } else return '<feed unit="50"/>';
};

/**
 * @param {Order} order
 * @param {{deviceName: string; ip:string; usbLegacyPrinter: boolean}} receiptPrinter
 * @param {boolean | null} isReturnReceipt
 * @param {boolean | null} isSignatureReceipt
 * @param {number | null} cashChange
 * @param {string | null} restaurantOnlineOrderUrl
 * @param {string | null} pickupScreenUrl
 * @returns {string}
 */
export const printReceipt = (
    order,
    receiptPrinter,
    isReturnReceipt,
    isSignatureReceipt,
    cashChange,
    restaurantOnlineOrderUrl,
    pickupScreenUrl
) => {
    const receiptXml = receiptBody(
        order,
        isReturnReceipt,
        isSignatureReceipt,
        cashChange,
        restaurantOnlineOrderUrl,
        pickupScreenUrl
    );
    if (isReturnReceipt) {
        const buyersXml = receiptXml.replace(printerConstants.EXTRA_RECEIPT_REPLACE_STRING, refundFields(true));
        let sellersXml = receiptXml.replace(printerConstants.EXTRA_RECEIPT_REPLACE_STRING, refundFields(false));
        // dont open drawer twice
        if (openDrawer) {
            sellersXml = sellersXml.replace(openDrawer, "");
        }
        sendToPrinter(receiptPrinter, sellersXml);
        sendToPrinter(receiptPrinter, buyersXml);
    } else if (isSignatureReceipt) {
        const buyersXml = receiptXml.replace(printerConstants.EXTRA_RECEIPT_REPLACE_STRING, signatureFields(true));
        const sellersXml = receiptXml.replace(printerConstants.EXTRA_RECEIPT_REPLACE_STRING, signatureFields(false));
        sendToPrinter(receiptPrinter, sellersXml);
        sendToPrinter(receiptPrinter, buyersXml);
    } else if (order.paymentMethodAmounts.some(p => p.paymentMethod === PaymentMethod.INVOICE)) {
        const { invoiceData } = order;
        const invoiceReceipt = `
            <feed unit="30"/>
            <text>_____________Faktura uppgifter____________&#10;</text>
            <feed unit="10"/>
            <text>Fakturakund:   ${escape(invoiceData.invoiceAddress.name)}&#10;</text>
            <text>Org nr:        ${invoiceData.organisationNumber}&#10;</text>
            <text>Adress:        ${escape(invoiceData.invoiceAddress.addressLine)}&#10;</text>
            <text>               ${invoiceData.invoiceAddress.postCode}, ${escape(
            invoiceData.invoiceAddress.city
        )}&#10;</text>
            <text>Referens:      ${escape(invoiceData.invoiceReference)}&#10;</text>
            <text>Kontaktperson: ${escape(invoiceData.contactName)}&#10;</text>
            `;

        const buyersXml = invoiceReceipt + signatureFields(false);
        const sellersXml =
            invoiceReceipt +
            `<feed unit="30"/>
            <text>${centerReceiptText("_______________Kundens kopia______________")}&#10;</text>`;
        const buyersXmlToSign = receiptXml
            .replace(printerConstants.EXTRA_RECEIPT_REPLACE_STRING, buyersXml)
            .replace(getQrCodeToShow(pickupScreenUrl, restaurantOnlineOrderUrl), "");
        const sellersCopy = receiptXml.replace(printerConstants.EXTRA_RECEIPT_REPLACE_STRING, sellersXml);
        sendToPrinter(receiptPrinter, buyersXmlToSign);
        sendToPrinter(receiptPrinter, sellersCopy);
    } else if (order.orderControlUnitValues.failedRequest && order.receiptType !== ReceiptType.FAILED) {
        const cashInvoice = receiptXml.replace(printerConstants.EXTRA_RECEIPT_REPLACE_STRING, cashInvoiceFields());
        sendToPrinter(receiptPrinter, cashInvoice);
        sendToPrinter(receiptPrinter, cashInvoice);
    } else {
        sendToPrinter(receiptPrinter, receiptXml);
    }
};

export const sendToPrinter = (receiptPrinter, xml) => {
    axios.post(
        `https://${receiptPrinter.ip}/cgi-bin/epos/service.cgi?devid=${receiptPrinter.deviceName}&timeout=10000`,
        xml
    );
};

export const printCashChange = (receiptPrinter, change, totalChange) => {
    const date = moment().format("YYYY-MM-DD HH:mm");
    const changeText = change > 0 ? "+" + formatSwedishFinancialNumbers(change) : formatSwedishFinancialNumbers(change);
    const totalText = formatSwedishFinancialNumbers(totalChange);
    const dateSpaces = printerConstants.NO_CHARACTERS_PER_LINE - date.length - 6;
    const changeSpaces = Math.max(printerConstants.NO_CHARACTERS_PER_LINE - changeText.length - 8, 0);
    const totalSpaces = Math.max(printerConstants.NO_CHARACTERS_PER_LINE_S2 - totalText.length - 6, 0);

    const cashChangeReceipt = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <text width="3" height="3"/>
        <text dw="true" dh="true"/>
        <text>Växel&#10;</text>
        <feed unit="10"/>
        <text width="1" height="1"/>
        <text>Datum:${" ".repeat(dateSpaces)}${date}&#10;</text>
        <text>Ändring:${" ".repeat(changeSpaces)}${changeText}&#10;</text>
        <text>------------------------------------------&#10;</text>
        <text dw="true" dh="true"/>
        <text>Total:${" ".repeat(totalSpaces)}${totalText}&#10;</text>
        <feed unit="30"/>
        <text width="1" height="1"/>
        <text>                          powered by qopla</text>
        <feed unit="60"/>
        <cut type="feed"/>
        </epos-print>
        </s:Body>
        </s:Envelope>`;

    axios.post(
        `https://${receiptPrinter.ip}/cgi-bin/epos/service.cgi?devid=${receiptPrinter.deviceName}&timeout=10000`,
        cashChangeReceipt
    );
};
const twoColumns = (name, price) => {
    const escapedName = escape(name);
    const totalText = formatSwedishFinancialNumbers(price);
    const totalSpaces = Math.max(
        printerConstants.NO_CHARACTERS_PER_LINE_S3 - escapedName.length - totalText.length - 6,
        0
    );
    return `${escapedName}${" ".repeat(totalSpaces)}${totalText}`;
};
const threeColumns = (name, quantity, price) => {
    const escapedName = escape(name);
    const totalText = price ? formatSwedishFinancialNumbers(price) : null;
    const totalTextQuantity = `${quantity} st`;
    const firstTotalSpaces = Math.max(34 - escapedName.length - totalTextQuantity.length - 6, 0);
    const secondTotalSpaces = totalText ? Math.max(18 - totalText.length - 6, 0) : null;
    return price
        ? `${escapedName}${" ".repeat(firstTotalSpaces)}${totalTextQuantity}${" ".repeat(
              secondTotalSpaces
          )}${totalText}`
        : `${escapedName}${" ".repeat(firstTotalSpaces)}${totalTextQuantity}`;
};
export const printXReport = (receiptPrinter, report) => {
    const endDate = moment.utc(report.endDate).local().format("YYYY-MM-DD HH:mm");

    const methods =
        report.paymentMethodAndAmounts.length > 0
            ? report.paymentMethodAndAmounts.map(payment => {
                  let tip = "";
                  if (payment.paymentMethod === PaymentMethod.CARD && payment.tip > 0) {
                      tip = `<text x="30"/>
                    <text>${twoColumns(" - varav dricks", payment.tip)}</text>
                    <feed unit="30"/>`;
                  }
                  return `<text x="30"/>
                    <text>${twoColumns(swedishPaymentMethod[payment.paymentMethod].swe, payment.amount)}</text>
                    <feed unit="30"/>
                    ${tip}
        `;
              })
            : [];

    const categoryTotalSale =
        report.categoryTotalSales.length > 0
            ? report.categoryTotalSales.map(category => {
                  return `<text reverse="false" ul="false" em="false" color="color_1"/>
                    <text x="30"/>
                    <text>${twoColumns(category.categoryName, category.totalSales)}</text>
                    <feed unit="30"/>
        `;
              })
            : [];
    const vat =
        report.totalSales !== 0
            ? report.vatRateAmountWithRefunds
                  .map(vatRateAmount => {
                      return `<text x="30"/>
                      <text>${twoColumns(`Moms ${vatRateAmount.vatRate}%`, vatRateAmount.amount)}</text>
                      <feed unit="30"/>`;
                  })
                  .join("")
            : "";
    const pos =
        report.posIngoingCashChange.length > 0 &&
        report.posIngoingCashChange.map(pos => {
            return `<feed unit="30"/>
        <text x="30"/>
        <text>${twoColumns(pos.posName, pos.amount)}</text>`;
        });
    const xReportXml =
        `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <text align="center"/>
        <text width="2" height="2"/>
        <text>X-dagrapport</text>
        <feed line="1"/>
        <text width="1" height="1"/>
        <text>${endDate}</text>
        <feed line="1"/>
        <text width="2" height="2"/>
        <text>${escape(report.shopName)}</text>
        <feed line="1"/>
        <text width="1" height="1"/>
        <text>Org nr : ${report.organisationNumber}</text>
        <feed line="2"/>
        <text align="left"/>
        <text x="15"/>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text font="special_a"/>
        <text>Försäljning per betalsätt</text>
        <feed unit="30"/>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        ` +
        methods?.join("") +
        `<text x="30"/>
        <text>${twoColumns("Totalt Netto", report.totalNetSales)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${twoColumns("Totalt Brutto", report.totalSales)}</text>
        <feed unit="60"/>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text x="15"/>
        <text>Försäljning per varugrupp</text>
        <feed unit="30"/>
        <feed line="0"/>` +
        categoryTotalSale.join("") +
        `<text reverse="false" ul="false" em="true" color="color_1"/>
        <text x="15"/>
        <text>Totalt</text>
        <feed unit="30"/>
        <feed line="0"/>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text x="30"/>
        <text>${twoColumns("Totalt Netto", report.totalNetSales)}</text>
        <feed unit="30"/>
        ` +
        vat +
        `<text x="30"/>
        <text>${twoColumns("Totalt Brutto(exkl returer)", report.totalSales)}</text>
        <feed line="2"/>
        <text align="left"/>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text x="15"/>
        <text>Växelkassa</text>
        <text reverse="false" ul="false" em="false" color="color_1"/>` +
        pos.join("") +
        `<feed line="2"/>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text x="15"/>
        <text>Övrigt</text>
        <feed unit="30"/>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text x="30"/>
        <text>${threeColumns("Antal sålda varor", report.sumSoldProducts)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${twoColumns("Rabatter", report.discounts.amount)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${threeColumns("Returer", report.refunds.count, report.refunds.amount)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${threeColumns("Kassakvitton", report.sumReceipts)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${threeColumns("Kvittokopior", report.copies.count, report.copies.amount)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${threeColumns("Övningskvitton", report.tests.count, report.tests.amount)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${threeColumns("Oavslutade notor", report.unfinished.count, report.unfinished.amount)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${threeColumns("Lådöppningar", report.sumOpenedRegister)}</text>
        <feed line="2"/>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text x="15"/>
        <text>Grand Total</text>
        <feed unit="30"/>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text x="30"/>
        <text>${twoColumns("Grand Total Netto", report.grandTotalNet)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${twoColumns("Grand Total Retur", report.grandTotalRefund)}</text>
        <feed unit="30"/>
        <text x="30"/>
        <text>${twoColumns("Grand Total", report.grandTotalSales)}</text>
        <feed unit="90"/>
        <text width="1" height="1"/>
        <text>                          Powered by Qopla</text>
        <feed/>
        <cut type="feed"/>
        </epos-print>
        </s:Body>
        </s:Envelope>`;

    return sendToPrinter(receiptPrinter, xReportXml);
};
