import { normalizeString } from "TempUtils";

/**
 * @deprecated
 * Switch to convertFileToBase64String
 */
export const fileToBase64String = file =>
    new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
            resolve({
                fileName: normalizeString(file.name),
                base64String: reader.result.split("base64,")[1]
            });
        reader.onerror = reject;
    });
