import { getMenuBundleProductSubtitle, getMenuProductSubtitle, getProductSubtitleWithPrice } from "PriceUtils";
import { treeNodeConstants } from "Constants";

// used to save price to menu product (regular and bundle) and update treenode subtitle
export const saveMenuProductPriceAndColor = (valuesObject, refProductOrBundle) => {
    const color = valuesObject.color;
    const oldPrice = String(valuesObject.price);
    const oldDefaultPrice = String(refProductOrBundle.defaultPrice);
    const isMenuBundleProduct = !!valuesObject.treeNode.menuBundleProduct;
    const activeHours = valuesObject.activeHours ? valuesObject.activeHours.map(days => days) : [];
    let price;

    if (oldPrice === oldDefaultPrice || oldPrice === "") {
        price = null;
    } else {
        if (typeof valuesObject.price === "string") {
            const priceWithNoCommas = valuesObject.price?.replace(/\,/g, ".");
            price = parseFloat(priceWithNoCommas);
        } else {
            price = valuesObject.price;
        }
    }

    // set new subtitle if any change is made
    if (
        valuesObject.price === refProductOrBundle.defaultPrice ||
        (valuesObject.price && valuesObject.price !== refProductOrBundle.defaultPrice)
    ) {
        if (isMenuBundleProduct) {
            valuesObject.treeNode.subtitle = getMenuBundleProductSubtitle(valuesObject.treeNode.menuBundleProduct);
        } else {
            valuesObject.treeNode.subtitle = getMenuProductSubtitle(valuesObject.treeNode.menuProduct);
        }
    }

    if (valuesObject.treeNode.id.includes(treeNodeConstants.TREE_MENU_PRODUCT)) {
        valuesObject.treeNode.menuProduct = { ...valuesObject.treeNode.menuProduct, price, color, activeHours };
    } else if (valuesObject.treeNode.id.includes(treeNodeConstants.TREE_MENU_BUNDLE_PRODUCT)) {
        valuesObject.treeNode.menuBundleProduct = {
            ...valuesObject.treeNode.menuBundleProduct,
            price,
            color,
            activeHours
        };
    }
    return valuesObject;
};

export const saveMenuProductModifications = (valuesObject, refProduct) => {
    const modificationDeleted =
        (valuesObject.treeNode.menuProduct?.modifications?.flavours.length > 0 &&
            valuesObject.modifications.flavours.length == 0) ||
        (valuesObject.treeNode.menuProduct?.modifications?.sizes.length > 0 &&
            valuesObject.modifications.sizes.length == 0);
    if (
        valuesObject.modifications.sizes.length > 0 ||
        valuesObject.modifications.flavours.length > 0 ||
        modificationDeleted
    ) {
        if (JSON.stringify(valuesObject.modifications) === JSON.stringify(refProduct.modifications)) {
            valuesObject.treeNode.menuProduct = {
                ...valuesObject.treeNode.menuProduct,
                modifications: null
            };
        } else {
            valuesObject.treeNode.menuProduct = {
                ...valuesObject.treeNode.menuProduct,
                modifications: valuesObject.modifications
            };
        }
    }
    return valuesObject;
};

export const convertBundleRefProductModificationsToIdList = (valuesObject, refLimitBundleCategoryWithProduct) => {
    const flattenedProducts = _.flatMap(valuesObject.limitBundleCategoryWithProduct, cat => cat.products);
    const flattenedRefProducts = _.flatMap(refLimitBundleCategoryWithProduct, cat => cat.products);
    const asd = flattenedProducts
        .filter((prod, idx) => {
            if (!!!prod.modifications) {
                return false;
            } else {
                const refProduct = flattenedRefProducts[idx];
                if (_.isEqual(refProduct.modifications, prod.modifications)) {
                    return false;
                } else {
                    return true;
                }
            }
        })
        .map(prod => ({
            refProductId: prod.id,
            modifications: {
                ...prod.modifications,
                sizes: prod.modifications.sizes
                    ? prod.modifications.sizes.map(size => ({
                          ...size,
                          price: 0
                      }))
                    : null,
                flavours: prod.modifications.flavours
                    ? prod.modifications.flavours.map(flavour => ({
                          ...flavour,
                          price: 0
                      }))
                    : null
            }
        }));
    return asd;
};

export const saveMenuProductBundleCategoryModifications = (
    valuesObject,
    refBundleProduct,
    refLimitBundleCategoryWithProduct
) => {
    // if values are equal to reference, null it and use reference values instead
    valuesObject.treeNode.menuBundleProduct = {
        ...valuesObject.treeNode.menuBundleProduct,
        menuBundleModifications:
            JSON.stringify(valuesObject.limitBundleCategoryWithProduct) ===
            JSON.stringify(refLimitBundleCategoryWithProduct)
                ? null
                : convertBundleRefProductModificationsToIdList(valuesObject, refLimitBundleCategoryWithProduct)
    };
    return valuesObject;
};

export const buildBundleCategoryLimitObject = (bundleProductCategories, menuBundleModifications, refProducts) => {
    return bundleProductCategories.map(limitCategory => {
        return {
            categoryName: limitCategory.name,
            color: limitCategory.color ? limitCategory.color : "",
            products: limitCategory.refProductIdList
                .map(refProductId => {
                    if (refProducts[refProductId]) {
                        //check if there are modifications on menu level
                        const modExistInMenu = menuBundleModifications
                            ? menuBundleModifications.find(m => m.refProductId === refProductId)
                            : undefined;

                        const modificationsToUse = modExistInMenu
                            ? modExistInMenu.modifications
                            : refProducts[refProductId].modifications;
                        return {
                            id: refProductId,
                            productName: refProducts[refProductId].name,
                            modifications: modificationsToUse,
                            overriddenModifications: !!modExistInMenu
                        };
                    } else {
                        return null;
                    }
                })
                .filter(prod => !!prod)
        };
    });
};

export const disableModificationPrice = (modsWithPrice, modType) => {
    if (modsWithPrice.length == 0) {
        return false;
    } else if (modsWithPrice.includes(modType)) {
        return false;
    }
    return true;
};
