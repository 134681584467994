import { escape } from "lodash";
import moment from "moment";

import { GLOBAL_TIP_PRODUCT_NAME, printerConstants } from "Constants";
import { CartProduct, EatingOption, Order, OrderWindow, Shop } from "Types";
import { formatDateToLocal, formatSwedishFinancialNumbers } from "./TextFormat";
import {
    addonsToXml,
    bundleItemsToPrint,
    commentToXml,
    discountNames,
    getQuantityOrWeightLabel,
    getSelectedModificationName,
    receiptShopName,
    sendToPrinter,
    totalAmountToXml
} from "./Printer";

import { pingReceiptPrinter } from "../components/poses/pos/utils/";
import { errorNotification } from "./";

type ReceiptPrinter = { deviceName: string; ip: string; usbLegacyPrinter: boolean };

export const emojiRegExp =
    /([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g;

const receiptHead = () => {
    return `<text reverse="true" ul="false" em="false" color="color_1"/>
                <text linespc="0"/>
                <text width="2" height="2"/>
                <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                <text dw="true" dh="true"/>
                <text>${centerReceiptText("Förhandsnota", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                <text dw="false" dh="false"/>
                <text width="2" height="2"/>
                <text>${centerReceiptText("Ej Kvitto ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                <text width="1" height="1"/>
                <text>${centerReceiptText("  ")}&#10;</text>
                <text reverse="false" ul="false" em="false" color="color_1"/>
                <feed unit="30"/>`;
};

const orderProductsToXml = (orderProducts: CartProduct[]) => {
    const getTotalAmount = orderProducts.reduce((total: number, order: CartProduct) => {
        return total + order.orderProduct.totalPrice;
    }, 0);

    const vatRate = orderProducts.reduce((vatRateTotal: number, order: CartProduct) => {
        const amount = order.orderProduct.totalPrice - order.orderProduct.totalNetPrice;

        return vatRateTotal + amount;
    }, 0);
    const amount = formatSwedishFinancialNumbers(vatRate);
    const vat = `   Moms ${orderProducts[0].orderProduct.vatRate.toFixed(1)}%:`;
    const noSpacesToAppend = printerConstants.NO_CHARACTERS_PER_LINE - amount.length - vat.length;
    const vatString = vat + " ".repeat(noSpacesToAppend) + amount;
    const totalAmountXml = totalAmountToXml(getTotalAmount);
    const vatRateXml = `<text>${vatString}&#10;</text>`;
    const orderProductsWithoutTip = orderProducts.filter(order => order.orderProduct.name !== GLOBAL_TIP_PRODUCT_NAME);

    const orderProduct = orderProductsWithoutTip.reduce((productXml: string, order: CartProduct) => {
        const product = order.orderProduct;
        const quantityOrWeightLabel = getQuantityOrWeightLabel(product);
        let size = "",
            flavour = "";
        if (product.modifications) {
            size = getSelectedModificationName(product.modifications.sizes);
            flavour = getSelectedModificationName(product.modifications.flavours);
        }
        let productName = `${size} ${flavour} ${product.name}`.trim().replace("  ", " ");

        const totalPrice = formatSwedishFinancialNumbers(product.totalPrice).replace("kr", "").trim();
        let noSpacesToAppend =
            printerConstants.NO_CHARACTERS_PER_LINE -
            productName.length -
            quantityOrWeightLabel.length -
            totalPrice.length;
        if (noSpacesToAppend < 1) {
            noSpacesToAppend = 1;
            productName = productName.substring(
                0,
                printerConstants.NO_CHARACTERS_PER_LINE - quantityOrWeightLabel.length - totalPrice.length - 1
            );
        }

        const productString = quantityOrWeightLabel + escape(productName) + " ".repeat(noSpacesToAppend) + totalPrice;
        productXml += `<text>${productString}&#10;</text>`;
        if (product.discountValue) {
            const discountValue = formatSwedishFinancialNumbers(-product.discountValue).replace("kr", "").trim();
            const indent = " ".repeat(quantityOrWeightLabel.length - 2);
            const discountSpacing = Math.max(
                printerConstants.NO_CHARACTERS_PER_LINE - discountValue.length - indent.length - 8,
                0
            );
            productXml += `<text>${indent}- Rabatt${" ".repeat(discountSpacing)}${discountValue}&#10;</text>`;
            productXml += discountNames(product.combinedDiscounts, indent);
        }
        if (product.selectedBundleProductItems) {
            productXml += bundleItemsToPrint(product);
        }
        if (product.addons) {
            productXml += addonsToXml(product.addons);
        }
        if (product.comment) {
            productXml += commentToXml(product.comment);
        }
        productXml;
        return productXml;
    }, "");
    const addTotal = `${orderProduct}
    <text>__________________________________________&#10;</text>
    <feed unit="15"/>
    <text width="2" height="2"/>
    ${totalAmountXml}
    <feed unit="5"/>
    <text width="1" height="1"/>
    ${vatRateXml}
    <feed unit="15"/>
    `;
    return addTotal;
};

export const advanceReceipt = async (
    receiptPrinter: ReceiptPrinter,
    getActiveOrderWindow: OrderWindow,
    selectedShop: Shop
) => {
    const isPrinterAvailable = await pingReceiptPrinter(receiptPrinter);

    if (!isPrinterAvailable) {
        await errorNotification("Skrivare ej ansluten", "Kontrollera dess anslutning");
        return;
    }
    const order = getActiveOrderWindow;
    const shop = selectedShop;
    const orderProductsXml = orderProductsToXml(order.cartProducts);
    const phoneNumberString = !!shop.contactInformation.phoneNumber
        ? `<text>Tel: ${shop.contactInformation.phoneNumber}&#10;</text>`
        : "";
    const mailString = !!shop.contactInformation.email
        ? `<text>Email: ${shop.contactInformation.email}&#10;</text>`
        : "";
    const adressString = !!shop.contactInformation.addressLine ? `${shop.contactInformation.addressLine}` : "";
    const invoiceReceipt = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <text align="center"/>
        <text width="2" height="2"/>
        ${receiptHead()}
        <feed unit="10"/>
        <text width="3" height="3"/>
        <text dw="true" dh="true"/>
        <text align="center" />
        ${receiptShopName(shop.name)}
        <text width="1" height="1"/>
        <text>Org.nr: ${shop.organisationNumber}&#10;</text>
        <text>${escape(adressString)}&#10;</text>
        <text>${shop.contactInformation.postCode}, ${shop.contactInformation.city}&#10;</text>
        ${phoneNumberString}
        ${mailString}
        <text>______________________&#10;</text>
         <feed unit="20"/>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text>${formatDateToLocal(moment())}&#10;</text>
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text>__________________________________________&#10;</text>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text align="left" />
        <feed unit="20"/>
        ${orderProductsXml}
        <feed unit="40"/>
        <text width="2" height="2"/>
        <text>${centerReceiptText("Ej Kvitto ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
        <feed unit="40"/>
        <text width="1" height="1"/>
        <text>                          powered by qopla</text>
        <feed unit="30"/>
        <cut type="feed"/>
        </epos-print>
        </s:Body>
        </s:Envelope> `;
    return sendToPrinter(receiptPrinter, invoiceReceipt);
};

export const printOrderNumberReceipt = (order: Order, receiptPrinter: ReceiptPrinter, pickupDisplayUrl: string) => {
    const isTakeAway = order.eatingOption === EatingOption.TAKE_AWAY;
    const pickupQrCodeToPrint = pickupDisplayUrl ? pickupQrCode(pickupDisplayUrl) : "";
    const orderNumberReceipt = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        ${orderNumberBox(order.orderNo, isTakeAway)}
        ${pickupQrCodeToPrint}
        <text>                          powered by qopla</text>
        <feed unit="30"/>
        <cut type="feed"/>
        </epos-print>
        </s:Body>
        </s:Envelope>`;
    return sendToPrinter(receiptPrinter, orderNumberReceipt);
};

const orderNumberBox = (orderNo: number, takeAway: boolean) => {
    const takeAwayText = takeAway ? "Takeaway" : "Äta här ";
    return `<text reverse="true" ul="false" em="false" color="color_1"/>
                <text linespc="0"/>
                <text width="2" height="2"/>
                <text>${centerReceiptText(" ", printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                <text dw="true" dh="true"/>
                <text>${centerReceiptText(orderNo.toString(), printerConstants.NO_CHARACTERS_PER_LINE_S2)}&#10;</text>
                <text dw="false" dh="false"/>
                <text width="1" height="1"/>
                <text>${centerReceiptText("Ordernummer ")}&#10;</text>
                <text>${centerReceiptText(takeAwayText)}&#10;</text>
                <text>${centerReceiptText("  ")}&#10;</text>
                <text reverse="false" ul="false" em="false" color="color_1"/>
                <feed unit="30"/>`;
};

const centerReceiptText = (text: string, fontSize = printerConstants.NO_CHARACTERS_PER_LINE) => {
    const receiptTextSpaces = " ".repeat(Math.floor(Math.max(fontSize - text.length, 0) / 2));

    const centeredText = `${receiptTextSpaces}${text}${receiptTextSpaces}`;

    return centeredText.length === fontSize ? centeredText : centeredText + " ";
};

export const pickupQrCode = (pickupScreenUrl: string) => {
    return `
        <text reverse="false" ul="false" em="true" color="color_1"/>
        <text>${centerReceiptText("↓↓↓ FÖLJ ORDER ↓↓↓")}&#10;</text>
        <text align="center"/>
        <symbol type="qrcode_model_2" level="default" width="5" height="5" size="0">${escape(pickupScreenUrl)}</symbol>
        <text>${centerReceiptText("↑↑↑ FÖLJ ORDER ↑↑↑")}&#10;</text>
        <text reverse="false" ul="false" em="false" color="color_1"/>
        <text align="left"/>
        <feed unit="50"/>`;
};
