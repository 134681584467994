import { START_KDS_COLOR_COUNTDOWN_SECONDS } from "Constants";
import moment from "moment";

export const getTimeLeftColor = value => {
    //value from 0 to 1
    const hue = ((1 - value) * 120).toString(10);
    if (hue === "NaN") {
        return null;
    }
    return [`hsla(${hue}, 100%, 50%, 1`];
};

export const calculateNewTimeLeftColor = (time, reverseTimeComparing = false) => {
    const pickupTime = moment.utc(time).unix();
    const now = moment.utc().unix();
    const timeDiff = reverseTimeComparing ? now - pickupTime : pickupTime - now;
    const noNegativeTime = Math.max(timeDiff, 0);
    const percentage =
        Math.min(noNegativeTime, START_KDS_COLOR_COUNTDOWN_SECONDS) /
        START_KDS_COLOR_COUNTDOWN_SECONDS;

    return reverseTimeComparing ? percentage : 1 - percentage;
};
